<template lang="html">
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Chi tiết đơn hàng: <b class="pull-right">{{ form.id }}</b></h6>
        </div>
        <div class="card-body order-title">
          <div class="right">
            <p>Ngày tạo: <b class="pull-right">{{ $util.dateTimeFormat(form.created_at) }}</b></p>
            <p>Ngày cập nhật: <b class="pull-right">{{ $util.dateTimeFormat(form.updated_at) }}</b></p>
            <p>Thời gian: <b class="pull-right">{{ form.number_times }} tháng</b></p>
            <p>Số tiền: <b class="pull-right">{{ $util.formatMoney(form.total_payment) }}</b></p>
            <p>Trạng thái:
              <b v-if="form.status" class="badge badge-success pull-right">Đã thanh toán</b>
              <b v-else class="badge badge-danger pull-right">Chưa thanh toán</b>
            </p>
          </div>
        </div>
      </div>

      <div class="card" v-if="form.payment_name">
        <div class="card-header">
          <h6 class="title">Cổng thanh toán</h6>
        </div>
        <div class="card-body order-title">
          <div>
            <p>Cổng thanh toán: <b class="pull-right">{{ form.paygate_name }}</b></p>
            <p>Hình thức thanh toán: <b class="pull-right">{{ form.payment_name }}</b></p>

            <p v-if="form.status_format == 'Chưa thanh toán'">Trạng thái thanh toán: <b class="badge badge-danger pull-right">{{ form.status_format }}</b></p>
            <div v-if="form.payment == 5 && form.status == 0 && expiredTimeBankTransfer">
              <div style="color: red; float: right;">Đã hết hạn thanh toán</div>
            </div>

            <div v-if="form.payment_transaction_id">
              <p>Mã giao dịch: <b>{{ form.payment_transaction_id }}</b></p>
              <div v-if="form.payment_logs">
                <p v-for="(value, key) in form.payment_logs"><span class="payment-log-item">{{ key }}</span>: <b>{{ value }}</b></p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Thông tin khách hàng
            <a class="pull-right" :href="`/#/users/${form.user_id}`" >{{form.user_id}}</a>
          </h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Tên đầy đủ:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Tên đầy đủ"
                class="full-width"
                v-model="form.name"
                :class="errors.has('name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="name"
                data-vv-as="Tên đầy đủ"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Tên:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Tên"
                class="full-width"
                v-model="form.first_name"
                :class="errors.has('first_name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="first_name"
                data-vv-as="Tên"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Họ:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Họ"
                class="full-width"
                v-model="form.last_name"
                :class="errors.has('last_name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="last_name"
                data-vv-as="Họ"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Email:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Email"
                class="full-width"
                v-model="form.email"
                :class="errors.has('email')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="email"
                data-vv-as="Email"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('email')">{{ errors.first('email') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>Điện thoại:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Điện thoại"
                class="full-width"
                v-model="form.phone"
              >
              </el-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Giới tính:</p>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.gender" placeholder="Giới tính" class="full-width">
                <el-option label="Nam" :value="'1'"></el-option>
                <el-option label="Nữ" :value="'0'"></el-option>
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p class="required">Tỉnh/TP:</p>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.province" placeholder="Tỉnh/thành phố" class="full-width">
                <el-option
                  v-for="option in provinces"
                  :key="option.id"
                  :label="option.name"
                  :value="option.name">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p class="required">Quận/huyện:</p>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.district" placeholder="Quận/huyện" class="full-width">
                <el-option
                  v-for="option in districts"
                  :key="option.id"
                  :label="option.name"
                  :value="option.name">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p class="required">Phường/xã:</p>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.ward" placeholder="Phường/xã" class="full-width">
                <el-option
                  v-for="option in wards"
                  :key="option.id"
                  :label="option.name"
                  :value="option.name">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Địa chỉ:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Địa chỉ"
                class="full-width"
                v-model="form.address"
                :class="errors.has('address')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="address"
                data-vv-as="Địa chỉ"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('address')">{{ errors.first('address') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Thông tin khác</h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Trạng thái thanh toán:</p>
            </div>
            <div class="col-sm-12">
              <my-select
                class="full-width"
                :attribute="listStatus"
                placeholder="Trạng thái thanh toán..."
                v-model="form.status"
                :class="errors.has('status')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="status"
                data-vv-as="Trạng thái thanh toán"
              >
              </my-select>
              <span class="text-danger" v-if="errors.has('status')">{{ errors.first('status') }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>

    <loading :active.sync="loading"></loading>
  </div>
</template>

<script>
import {Select, Option, Button, Tag, Input, Tabs, TabPane, Upload, DatePicker, Switch, Radio, Message, Dialog} from 'element-ui'
import MySelect from 'src/components/UIComponents/Select'
import moment from 'moment'
import { mapState } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    ElSelect: Select,
    ElOption: Option,
    ElButton: Button,
    ElTag: Tag,
    ElInput: Input,
    ElTabs: Tabs,
    ElTabPane: TabPane,
    ElUpload: Upload,
    ElDatePicker: DatePicker,
    ElSwitch: Switch,
    ElRadio: Radio,
    MySelect,
    ElDialog: Dialog,
    Loading
  },
  data() {
    return {
      form: {},
      loading: false,
      listStatus: {
        options: [
          {
            title: 'Đã thanh toán',
            value: 1
          },
          {
            title: 'Chưa thanh toán',
            value: 0
          },
        ]
      }
    }
  },

  async beforeCreate() {
    let id = this.$route.params.id;
    await this.$store.dispatch('fetchProvinces');
    await this.$store.dispatch('fetchOrderVipDetail', id);
    await this.$store.dispatch('setPageTitle', 'Chỉnh sửa đơn hàng');
    await this.$store.dispatch('setCurrentActions', [
      {
        label: 'Gửi lại email',
        type: 'info',
        icon: '',
        callback: this.resendEmail
      },
      {
        label: 'Cập nhật đơn hàng',
        type: 'primary',
        icon: '',
        callback: this.save
      }
    ])
  },

  computed: {
    ...mapState({
      originalForm: state => state.orderOrderVipDetail
    }),

    provinces() {
      let data = this.$store.state.provinces;
      return data;
    },

    districts() {
      let data = this.$store.state.districts;
      return data;
    },

    wards() {
      let data = this.$store.state.wards;
      return data;
    },

    expiredTimeBankTransfer() {
      if (!this.form.created_at) {
        return false;
      }
      let now = new Date().getTime();
      let register = new Date(this.form.created_at).getTime();
      if (now - register > 48 * 60 * 60 * 1000) {
        return true;
      }
      return false;
    }
  },

  methods: {

    resendEmail() {
      this.$store.dispatch('resendEmailOrderVip', this.form.id).then(res => {
        Message({message: 'Gửi email thành công', type: 'success'});
      });
    },

    cancel() {
      this.$router.push({name: 'OrderVip'});
    },

    async save() {
      let self = this;
      await this.$validator.validateAll().then(async function (result) {
        if (!result) return;
        let data = Object.assign({}, self.form);

        self.loading = true;

        await self.$store.dispatch('updateOrderVip', data).then(res => {
          let id = self.$route.params.id;
          self.$store.dispatch('fetchOrderVipDetail', id);
          Message({
            message: 'Cập nhật thành công',
            type: 'success'
          });
        }, error => {
          Message({message: error, type: 'error'});
        });

        self.loading = false;

      });
    },

    async loadDistricts(province_id) {
      await this.$store.dispatch('fetchDistricts', province_id);
      if (this.form.district && this.districts && this.districts.length) {
        let item = this.districts.find(x => x.name === this.form.district);
        if (item && item.id) {
          this.$store.dispatch('fetchWards', item.id);
        }
      }
    }

  },
  watch: {
    originalForm(val) {
      this.form = Object.assign({}, val);
    },

    'form.province'(value) {
      if (this.provinces && this.provinces.length) {
        let item = this.provinces.find(x => x.name === value);
        if (item && item.id) {
          this.loadDistricts(item.id);
        }
      }
    },

    'form.district'(value) {
      if (this.districts && this.districts.length) {
        let item = this.districts.find(x => x.name === value);
        if (item && item.id) {
          this.$store.dispatch('fetchWards', item.id);
        }
      }
    }
  },

  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.$store.state.orderOrderVipDetail = [];
  }
}
</script>

<style lang="scss">
  .order-title .left{
    margin-right: 10px;
  }
  p {
    margin-bottom: 5px;
  }
  .el-input {
    margin-bottom: 10px;
  }
  .badge.badge-danger {
    white-space: normal;
    line-height: 13px;
    text-align: left;
  }
  .notes {
    margin-bottom: 20px;
  }
  .payment-log-item {
    text-transform: capitalize;
  }
  .navbar-nav {
    .el-button {
      margin-left: 10px !important;
    }
  }
  .el-dialog {
    margin-top: 5vh !important;
  }

  .el-dialog p {
    word-break: break-word;
  }

</style>
